import { render, staticRenderFns } from "./SkillBadge.vue?vue&type=template&id=d29dacb8&scoped=true&"
import script from "./SkillBadge.vue?vue&type=script&lang=js&"
export * from "./SkillBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d29dacb8",
  null
  
)

export default component.exports